import {
	FetchFn,
	SiteAssetsClientTopology,
	SiteAssetsManifests,
	SiteAssetsModuleName,
	TbElementsManifests,
} from '@wix/thunderbolt-symbols'
import { ModuleFetcherRequest, SiteAssetsModuleFetcher } from 'site-assets-client'
import _ from 'lodash'

type Topology = {
	pathInFileRepo:
		| SiteAssetsClientTopology['pathOfTBModulesInFileRepoForFallback']
		| SiteAssetsClientTopology['pathToEditorElementsModulesInFileRepoForFallback']
	fileRepoUrl: SiteAssetsClientTopology['fileRepoUrl']
}
const loadModule = (
	moduleName: SiteAssetsModuleName,
	manifests: SiteAssetsManifests,
	{ pathInFileRepo, fileRepoUrl }: Topology,
	fetchFn: FetchFn,
	env: 'web' | 'webWorker' = 'web'
) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	return async (module: object = {}, exports: object = {}) => {
		const pathInBeckyRepo = `${pathInFileRepo}${env === 'webWorker' ? 'site-assets-webworker/' : ''}`

		const moduleHash: string = manifests[env].modulesToHashes[moduleName]
		const moduleFileUrl = `${fileRepoUrl}/${pathInBeckyRepo}${moduleName}.${moduleHash}.js`
		// TODO: handle failure
		const script = await fetchFn(moduleFileUrl).then((resp) => resp.text())

		if (env === 'web') {
			const webpackRuntimeBundleHash: string = manifests[env].webpackRuntimeBundle
			const webpackRuntimeBundleUrl = `${fileRepoUrl}/${pathInBeckyRepo}webpack-runtime.${webpackRuntimeBundleHash}.js`
			// TODO: handle failure
			const webpackRuntime = await fetchFn(webpackRuntimeBundleUrl).then((resp) => resp.text())

			// eslint-disable-next-line no-eval
			eval(webpackRuntime)
		}
		// eslint-disable-next-line no-eval
		eval(script)
		// @ts-ignore
		return module.exports.default
	}
}

async function loadRequireJS() {
	// @ts-ignore
	await window.ThunderboltElementsLoaded
	await new Promise((resolve, reject) => {
		const script = document.createElement('script')
		// TODO adapt protocol accoring to request
		script.src = 'https://cdnjs.cloudflare.com/ajax/libs/require.js/2.3.6/require.min.js'
		script.onload = resolve
		script.onerror = reject
		document.head.appendChild(script)
	})

	// @ts-ignore
	window.define('_', [], () => _)
}

const loadDataFixersModule = (
	moduleName: string,
	version: string,
	clientTopology: SiteAssetsClientTopology,
	env: 'web' | 'webWorker' = 'web',
	fetchFn: FetchFn
) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	return async (module: object = {}, exports: object = {}) => {
		const getModuleFileUrl = () => {
			const getHighestVersion = () => {
				const splitVersion = version.split('.')
				const major = parseInt(splitVersion[0], 10)
				const minor = parseInt(splitVersion[1], 10)

				return major > 1 && minor > 1116 ? version : '1.1116.0'
			}

			const highestVersion = getHighestVersion()

			const santaDataFixerModuleSuffix = env === 'web' ? 'thunderbolt' : 'thunderbolt-webworker'

			if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
				// use hardcoded version that includes thunderbolt dedicated build
				return `${clientTopology.moduleRepoUrl}/${moduleName}@${highestVersion}/dist/${moduleName}-${santaDataFixerModuleSuffix}.js`
			} else {
				return `${clientTopology.moduleRepoUrl}/${moduleName}@${highestVersion}/dist/${moduleName}-${santaDataFixerModuleSuffix}.min.js`
			}
		}

		if (env === 'web') {
			const waitForRequireJsToLoad = loadRequireJS()

			return new Promise(async (resolve, reject) => {
				await waitForRequireJsToLoad
				__non_webpack_require__([getModuleFileUrl()], (moduleFile: any) => resolve(moduleFile), reject)
			})
		} else {
			// TODO: handle failure
			const dataFixerScript = await fetchFn(getModuleFileUrl()).then((resp) => resp.text())

			// eslint-disable-next-line no-eval
			eval(dataFixerScript)

			// @ts-ignore
			return module.exports
		}
	}
}

export const clientModuleFetcher = (
	fetchFn: FetchFn,
	clientTopology: SiteAssetsClientTopology,
	manifests: { thunderbolt: SiteAssetsManifests; tbElements: TbElementsManifests },
	env: 'web' | 'webWorker' = 'web'
): SiteAssetsModuleFetcher => {
	return {
		fetch: async <T>(request: ModuleFetcherRequest): Promise<T> => {
			const { module, version } = request

			if (module.startsWith('thunderbolt-')) {
				const topology: Topology = {
					fileRepoUrl: clientTopology.fileRepoUrl,
					pathInFileRepo: clientTopology.pathOfTBModulesInFileRepoForFallback,
				}
				return await loadModule(module as SiteAssetsModuleName, manifests.thunderbolt, topology, fetchFn, env)()
			} else if (module.startsWith('siteAssets')) {
				const topology: Topology = {
					fileRepoUrl: clientTopology.fileRepoUrl,
					pathInFileRepo: clientTopology.pathToEditorElementsModulesInFileRepoForFallback,
				}

				return await loadModule(
					module as SiteAssetsModuleName,
					manifests.tbElements as SiteAssetsManifests,
					topology,
					fetchFn,
					env
				)()
			} else {
				return await loadDataFixersModule(module, version, clientTopology, env, fetchFn)()
			}
		},
	}
}
