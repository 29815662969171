const runner = async () => {
	if (window.__IE_deprecation__) {
		return
	}

	await window.lodashPromise
	require('./client')
}

runner()
